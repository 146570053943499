import React, { Component } from 'react';
import { AboutContainer } from './About.styles';

export default class About extends Component {
  render() {
    return (
      <AboutContainer>
        <div className="c-about">
          <h4>ABOUT</h4>
          <div className="o-container">
            <h2>WE CREATE INTERACTIVE EXPERIENCES</h2>
            <h4 className="c-about__info">
              BisonPlay is a game and media development company who embrace technology to develop the best design
              solutions, whether it's App development, creating a totally awesome website or developing your brand
              identity. <br />
              <br />
              In an increasingly competitive environment, we take pride in developing solutions that tell the right
              story for your brand whilst paying attention to detail. With over 20 years combined experience and a
              forward thinking approach, we focus on innovative ways to engage your target market.
              <br />
              <br />
              Headed by founder and developer Tobias
            </h4>
            <div className="c-about__profiles">
              <div className="c-about__profiles-profile">
                <img alt="Tobias" width="100" src={require('../images/tobias.png')} />
                <h3>Tobias</h3>
                <p>Founder & Developer</p>
              </div>
            </div>
          </div>
        </div>
      </AboutContainer>
    );
  }
}
