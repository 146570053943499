import React, { useEffect } from 'react';
import { ContactContainer } from './Contact.styles';

export default () => {
  useEffect(() => {
    window.particlesJS.load('particles2-js', `/particles.json`, function () {});
  }, []);

  return (
    <ContactContainer>
      <div className="c-contact">
        <div className="o-particles-js o-particles-js--contact" id="particles2-js"></div>
        <h4>FOR PRESS AND SUPPORT</h4>
        <div className="o-container">
          <a
            className="c-contact__email"
            href="mailto:info@bisonplay.com"
            onClick={() => {
              window.ga('send', 'event', 'Email', 'email', 'Contact us click');
            }}
          >
            INFO@BISONPLAY.COM
          </a>
          <a
            className="c-contact__btn"
            href="mailto:info@bisonplay.com"
            onClick={() => {
              window.ga('send', 'event', 'Email', 'email', 'Sponsor click');
            }}
          >
            <p>NOW ACCEPTING SPONSORSHIP, PLEASE GET IN CONTACT</p>
            <img alt="right arrow" width="32" src={require('../images/right-arrow.svg')} />
          </a>
        </div>
      </div>
    </ContactContainer>
  );
};
