import React, { Component } from 'react';
import { MediaContainer } from './Media.styles';

export default class Media extends Component {
  gaClick(category, action, label) {
    window.ReactGA.event({
      category: 'Promotion',
      action: 'Displayed Promotional Widget',
      label: 'Homepage Thing',
      nonInteraction: true,
    });
  }

  render() {
    return (
      <MediaContainer>
        <div className="c-media amina">
          <h4>GAMES & MEDIA</h4>
          <img className="logo-img" src="img/logo.png" alt="logo" />
          <img className="amina-img" src="img/amina.png" alt="amina" />
          <div className="c-media__info amina">
            <a
              href="https://apps.apple.com/app/queen-amina/id1548189454"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                window.ga('send', 'event', 'SiteLink', 'Click', 'Queen Amina App Store Click');
                window.fbq('track', 'Queen Amina App Store Click');
              }}
              className="c-button c-button--brown"
            >
              {' '}
              <img
                alt="apple button"
                className="c-media__info-apple"
                width="25"
                src={require('../images/apple-btn.svg')}
              />
              IOS Download
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.bisonplay.low"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                window.ga('send', 'event', 'SiteLink', 'Click', 'Queen AminaGoogle Play Click');
                window.fbq('track', 'Queen Amina Google Play Click');
              }}
              className="c-button c-button--brown"
            >
              {' '}
              <img
                alt="android button"
                className="c-media__info-android"
                width="25"
                src={require('../images/android.svg')}
              />
              Android Download
            </a>
          </div>
        </div>
        <div className="c-media okada">
          <img
            alt="cloud right"
            className="c-media__clouds c-media__clouds--cloudright"
            height="73"
            src={require('../images/cloudright.svg')}
          />
          <img
            alt="cloud left"
            className="c-media__clouds c-media__clouds--cloudleft"
            height="73"
            src={require('../images/cloudleft.svg')}
          />
          <div className="c-media__holder">
            <video className="c-media__holder-gif" src="/video/loop.mp4" playsInline autoPlay loop muted />
            <img alt="hand" className="c-media__holder-hand" src={require('../images/hand.png')} />
          </div>

          <div className="c-media__info">
            <a
              href="https://apps.apple.com/us/app/id1534228693"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                window.ga('send', 'event', 'SiteLink', 'Click', 'MR Okada App Store Click');
                window.fbq('track', 'MR Okada App Store Click');
              }}
              className="c-button c-button--brown"
            >
              {' '}
              <img
                alt="apple button"
                className="c-media__info-apple"
                width="25"
                src={require('../images/apple-btn.svg')}
              />
              IOS Download
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.bisonplay.mrokadahd"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                window.ga('send', 'event', 'SiteLink', 'Click', 'MR Okada Google Play Click');
                window.fbq('track', 'MR Okada Google Play Click');
              }}
              className="c-button c-button--brown"
            >
              {' '}
              <img
                alt="android button"
                className="c-media__info-android"
                width="25"
                src={require('../images/android.svg')}
              />
              Android Download
            </a>
            <h1>Mr Okada HD is out now!</h1>
            <p>
              As a hard working hustler, he has taken on several jobs to make ends meet. He is a mechanic and saved
              enough money to purchase an okada bike to add to his monthly income. Mr Okada dreams big and his main goal
              is to become a top selling music artist.{' '}
            </p>
          </div>
          <div className="c-media__scape c-media__scape--1"></div>
          <div className="c-media__scape c-media__scape--2"></div>
        </div>
      </MediaContainer>
    );
  }
}
