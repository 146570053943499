import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { HeaderContainer } from './Header.styles';
export default class Header extends Component {
  render() {
    return (
      <HeaderContainer>
        <div className="l-header">
          <div className="o-container">
            <div className="l-header__content">
              <img alt="logo" className="l-header__content-logo" width="148" src={require('../images/logo.svg')} />
            </div>
            <Link to={`contact`}>CONTACT</Link>
          </div>
        </div>
      </HeaderContainer>
    );
  }
}
