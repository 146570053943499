import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Main from './components/Main';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={`/about`}>
            <Main />
          </Route>
          <Route path={`/media`}>
            <Main />
          </Route>
          <Route path={`/contact`}>
            <Main />
          </Route>
          <Route path={`/`}>
            <Main />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
