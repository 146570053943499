import styled from 'styled-components';
import theme from '../theme';

export const ContactContainer = styled.div`
  display: grid;
  .c-contact {
    position: relative;
    min-height: 50%;
    background-color: ${theme.color.bgColor};

    .particles-js {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
    }

    h4 {
      position: relative;
      z-index: 2;
      font-family: BrandonGrotesque-Bold;
      text-align: center;
      color: ${theme.color.white};
      padding: 55px;
      padding-bottom: 22px;
      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        padding-bottom: 55px;
      }
    }

    .o-container {
      position: relative;
      z-index: 2;
    }

    a {
      text-align: center;
      color: ${theme.color.white};
      margin-bottom: 40px;
      text-decoration: none;
    }

    &__email {
      display: block;
      font-size: 2.8em;
      font-family: BrandonGrotesque-Bold;
      padding-left: 8.47458%;
      padding-right: 8.47458%;
      margin-bottom: 40px;

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        padding-left: 16.94915%;
        padding-right: 16.94915%;
        font-size: 4.8em;
      }
    }

    &__btn {
      position: relative;
      z-index: 2;
      width: 83.05085%;
      display: block;
      margin: 0 auto;
      font-family: BrandonGrotesque-Bold;
      background-color: ${theme.color.grey};
      font-size: 1.8em !important;
      padding: 20px;

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        width: 624px;
      }

      p {
        display: inline;
        font-size: 1em;
        text-align: left;
        margin-right: 30px;
      }

      img {
        display: inline;
      }
    }
  }
`;
