const theme = {
  color: {
    white: '#fff',
    black: '#000',
    bgColor: '#1D1D1B',
    grey: '#9E9E9E',
    darkbrown: '#241c06',
    textColor: '#1e0806',
  },

  breakpoints: {
    mobileSmallPortrait: '320px', // iPhone 4/4s/5/5s
    mobileMidPortrait: '375px', // iPhone 6
    mobileLargePortrait: '414px', // iPhone 6+

    mobileSmallLandscape: '480px', // iPhone 4/4s
    mobileMidLandscape: '568px', // iPhone 5/5s
    mobileLargelandscape: '667px', // iPhone 6
    mobileXlargeLandscape: '736px', // iPhone 6+

    tabletPortrait: '768px',
    tabletLandscape: '1024px',

    desktopSmall: '1300px',
    desktopMid: '1400px',
    desktopLarge: '1600px',
  },
};

export default theme;
