import React, { Component } from 'react';
import { FooterContainer } from './Foter.styles';

export default class Footer extends Component {
  render() {
    return (
      <FooterContainer>
        <footer className="l-footer">
          <div className="o-container">
            <img
              alt="footer logo"
              className="l-footer__footer"
              width="110"
              src={require('../images/footer_logo.svg')}
            />
            <p>BISONPLAY LIMITED. Company number 10167752</p>
          </div>
        </footer>
      </FooterContainer>
    );
  }
}
