import styled from 'styled-components';
import theme from '../theme';
import scape1 from '../images/scape-1.png';
import scape2 from '../images/scape-2.png';

export const MediaContainer = styled.div`
  display: grid;
  min-height: 100vh;
  .c-media {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    &.amina {
      background: url('/img/desertbg.jpg') no-repeat;
      background-size: cover;
      background-position: center top;
      min-height: 100vh;

      .logo-img {
        position: absolute;
        margin: auto;
        top: 20%;
        left: 0;
        right: 0;
        max-width: 300px;
        @media (min-width: ${theme.breakpoints.tabletPortrait}) {
          top: auto;
          max-width: 500px;
        }
      }
      .amina-img {
        position: absolute;
        margin: auto;
        left: 20px;
        bottom: 0;
        max-height: 50%;
        @media (min-width: ${theme.breakpoints.tabletPortrait}) {
          max-height: 70%;
        }
      }
    }

    &.okada {
      background: #5550af; /* Old browsers */
      background: -moz-linear-gradient(top, #5550af 8%, #c4a1c5 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #5550af 8%, #c4a1c5 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        #5550af 8%,
        #c4a1c5 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5550af', endColorstr='#c4a1c5',GradientType=0 ); /* IE6-9 */
    }

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5550af+8,c4a1c5+100 */

    h4 {
      font-family: BrandonGrotesque-Bold;
      text-align: center;
      color: ${theme.color.bgColor};
      padding: 55px;
      padding-bottom: 22px;

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        padding-bottom: 55px;
      }
    }

    &__clouds {
      position: absolute;
      opacity: 0.2;
      &--cloudright {
        top: 11%;
        right: 10%;
      }

      &--cloudleft {
        bottom: 140px;
        left: 1%;
      }
    }

    &__holder {
      position: relative;
      z-index: 1;
      width: 90%;
      max-width: 669px;
      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        float: left;
        width: 50%;
      }

      &-hand {
        position: relative;
        width: 100%;
      }

      &-gif {
        position: absolute;
        width: 59.79%;
        top: 0;
        left: 0;
        margin-top: 30%;
        margin-left: 29.4%;
      }
    }

    &__info {
      position: relative;
      z-index: 1;
      width: 100%;
      margin-top: 20px;
      &.amina {
        * {
          font-family: BrandonGrotesque-Bold;
        }
      }

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        width: 49.15254%;
        float: right;
        margin-right: 0;
        margin-top: 12%;
        padding-left: 10px;
        padding-right: 10px;
        &.amina {
          * {
            font-family: BrandonGrotesque-Bold;
          }
        }
      }

      p {
        font-family: Avenir-Roman;
        text-align: center;
      }

      a {
        position: relative;
        left: 50%;
        margin-left: -147px;
        margin-bottom: 10px;
        text-align: center;
        line-height: 60px;
        text-decoration: none;

        img {
          position: relative;
          float: left;
          left: 20px;
          top: 50%;
        }
      }

      &-apple {
        margin-top: -17px;
      }

      &-android {
        opacity: 0.4;
        margin-top: -14px;
        @media (min-width: ${theme.breakpoints.tabletPortrait}) {
          margin-bottom: 120px;
        }
      }

      &-icons {
        text-align: center;
        margin-bottom: 20px;
        img {
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    h1 {
      font-family: FatFrank-Regular;
      text-align: center;
      margin-bottom: 20px;

      padding-left: 8.47458%;
      padding-right: 8.47458%;

      @media (min-width: ${theme.breakpoints.tabletLandscape}) {
        font-size: 4.5em;
      }
      @media (min-width: ${theme.breakpoints.desktopSmall}) {
        font-size: 5.5em;
      }
    }

    p {
      color: ${theme.color.textColor};
      padding-left: 8.47458%;
      padding-right: 8.47458%;
      font-family: FatFrank-Regular;
      text-align: center;
      margin-bottom: 20px;

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        text-align: left;
      }
    }

    &__scape {
      position: absolute;
      width: 100%;
      z-index: 0;
      bottom: 0;

      &.c-media__scape--1 {
        height: 121px;
        background: url(${scape1});
        background-position: 0px 0px;
      }

      &.c-media__scape--2 {
        height: 73px;
        background: url(${scape2});
        background-position: 0px 0px;
      }
    }
  }
`;
