import styled from 'styled-components';
import theme from '../theme';

export const AboutContainer = styled.div`
  display: grid;
  .c-about {
    position: relative;
    min-height: 50%;
    background-color: ${theme.color.white};

    h2 {
      font-family: BrandonGrotesque-Bold;
      text-align: center;
      padding-left: 8.47458%;
      padding-right: 8.47458%;

      color: ${theme.color.bgColor};
      margin-bottom: 40px;

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        font-size: 3.2em;
      }

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        padding-left: 16.94915%;
        padding-right: 16.94915%;
      }
    }

    h4 {
      font-family: BrandonGrotesque-Bold;
      text-align: center;
      padding: 55px;
      color: ${theme.color.bgColor};
      padding-bottom: 22px;

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        padding-bottom: 55px;
      }
    }

    .c-about__info {
      padding: 0;
      padding-left: 8.47458%;
      padding-right: 8.47458%;

      width: 100%;
      font-family: Avenir-Roman;
      text-align: left;
      margin-bottom: 40px;
      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        width: 49.15254%;
        float: left;
        margin-right: 1.69492%;
      }
    }

    .c-about__profiles {
      padding: 0;
      padding-left: 8.47458%;
      padding-right: 8.47458%;

      width: 100%;
      font-family: Avenir-Roman;
      text-align: left;
      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        width: 49.15254%;
        float: right;
        margin-right: 0;
      }

      img {
        display: block;
        margin: 0 auto 40px;
      }
      &-profile {
        width: 49.15254%;
        margin: auto;
      }

     /* &-profile:last-child {
        width: 49.15254%;
        float: right;
        margin-right: 0;
      }*/

      p,
      h3 {
        text-align: center;
        color: ${theme.color.bgColor};
      }

      h3 {
        font-family: Avenir-Medium;
      }
      margin-bottom: 50px;

      &:after {
        content: '';
        display: table;
        clear: both;
      }
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
`;
