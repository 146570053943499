import styled from 'styled-components';

export const HeaderContainer = styled.div`
  .l-header {
    position: fixed;
    top: 0;
    z-index: 2;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    padding: 58px;
    .o-container {
      .l-header__content {
        .l-header__content-logo {
        }
      }
    }

    a {
      font-family: BrandonGrotesque-Bold;
      position: fixed;
      top: 30px;
      float: right;
      right: 30px;
      color: #fff;
      z-index: 100;
    }
  }
`;
