import React, { Component } from 'react';

import gsap, { TweenMax, Power2 } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

import Header from './Header';
import Home from './Home';
import Media from './Media';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import { GlobalStyle } from './Main.styles';

gsap.registerPlugin(ScrollToPlugin);

class Main extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidUpdate() {
    let path = window.location.pathname.split(`/`)[1];
    let subpath = window.location.pathname.split(`/`)[2] || '';

    if (path.length > 0)
      TweenMax.to(window, 1, {
        scrollTo: { y: document.querySelector(`.c-${path} ${subpath}`).parentElement.offsetTop },
        ease: Power2.easeOut,
      });
  }
  componentDidMount() {
    let path = window.location.pathname.split(`/`)[1];
    if (path.length > 0) {
      TweenMax.to(window, 1, {
        scrollTo: { y: document.querySelector(`.c-${path}`).parentElement.offsetTop },
        ease: Power2.easeOut,
        delay: 0.7,
      });
    }
    document.addEventListener('scroll', this.handleScroll);
    document.addEventListener('touchstart', () => {
      const video = document.querySelector('.c-media__holder-gif');
      console.log('video', video);
      video.play();
    });

    window.particlesJS.load('particles-js', `/particles.json`, function () {});
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > 50) TweenMax.to(document.querySelector(`.l-header__content-logo`), 0.5, { y: -100 });
    else TweenMax.to(document.querySelector(`.l-header__content-logo`), 0.5, { y: 0 });
    if (
      window.pageYOffset > document.querySelector(`.c-about`).offsetTop - 50 &&
      window.pageYOffset < document.querySelector(`.c-contact`).offsetTop
    )
      document.querySelector(`.l-header a`).style.color = '#1D1D1B';
    else document.querySelector(`.l-header a`).style.color = '#FFFFFF';

    TweenMax.to(document.querySelector(`.c-media__scape--1`), 1, {
      backgroundPosition: `${window.pageYOffset * -0.1}px 0px`,
      ease: Power2.easeOut,
    });
    TweenMax.to(document.querySelector(`.c-media__scape--2`), 1, {
      backgroundPosition: `${window.pageYOffset * -0.4}px 0px`,
      ease: Power2.easeOut,
    });
  }

  documentHeight() {
    return Math.max(window.innerHeight, document.body.offsetHeight, document.documentElement.clientHeight);
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <div id="l-wrapper" className="c-app">
          <div className="o-particles-js o-particles-js--home" id="particles-js"></div>
          <Home text="Home page" />
          <Media text="Home page" />
          <About text="About page" />
          <Contact text="Contact page" />
          <Header />
          <Footer />
        </div>
      </>
    );
  }
}

export default Main;
