import React, { Component } from 'react';
import { HomeContainer } from './Home.styles';

export default class Home extends Component {
  render() {
    return (
      <HomeContainer>
        <div className="c-home__content">
          <h1>BISONPLAY IS A GAME & MEDIA DEVELOPMENT COMPANY</h1>
          <p>Check out the new game Queen Amina, out now on iOS and Android. Download links below.</p>
          <img alt="down arrow" height="30" src={require('../images/down-arrow.svg')} />
        </div>
      </HomeContainer>
    );
  }
}
