import { createGlobalStyle } from 'styled-components';
import theme from '../theme';

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  button {
    -webkit-tap-highlight-color: transparent;
  }

  body {
    background-color: ${theme.color.bgColor};
    color: ${theme.color.white};
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
    html.is-masquerading & {
      padding-top: 34px;

      &:before {
        content: 'you are in masquerade mode';
        text-transform: uppercase;
        text-align: center;
        font-size: 1.2em;
        position: fixed;
        padding: 10px 0;
        display: block;
        color: ${theme.color.white};
        z-index: 20;
        width: 100%;
        top: 0;
      }
    }
  }

  /* Grid objects */

  .o-container {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

  /* Grid */

  .o-particles-js {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    &--contact {
      /*@include calc( height, '100% + 0px')*/
    }
  }

  * {
    box-sizing: border-box;
  }

  #l-wrapper {
    background-color: ${theme.color.bgColor};
    width: 100%;
    height :100vh;
    html.lt-ie9 & {
      text-align: center;
      padding: 20px 0;
    }
  }

  @media (min-width: 1301px) {
    h1 {
    font-size: 6em;
  }
  }


  .l-main {
    text-align: center;

    .l-main__content {
       padding-left: 8.47458%;
 padding-right: 8.47458%;

      @include breakpoint($mobile-small-landscape $tablet-portrait) {
         padding-left: 16.94915%;
 padding-right: 16.94915%;
      }

      @include breakpoint(($tablet-portrait + 1)) {
        @include pad(3);
      }
    }
  }

  h1,
h2,
h3,
h4,
h5,
h6,
p,
small {
  padding:0;
  margin:0;

  &:last-child {
    margin-bottom:0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom:10px;
  color: $white;
}

p,
small {
}

p {
  margin-bottom:20px;
}

small {
  margin-bottom:10px;
  display:block;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p, .p,
a, .a,
span, .span,
small, .small {
 // @extend %frutiger-light;
}

strong{
  font-weight: normal;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
small, .small {
  font-weight:normal;
  line-height:1.2em;
}


h1, .h1 {
  font-size:3.4em;

  @media (min-width: ${theme.breakpoints.tabletPortrait}){
    font-size: 4.8em;
  }
  @media (min-width: ${theme.breakpoints.desktopSmall}){
    font-size: 6em;
  }

}

h2, .h2 {
  font-size: 3.4em;

  @media (min-width: ${theme.breakpoints.tabletPortrait}){
    font-size: 4.8em;
  }

}

h3, .h3 {
  font-size: 3.2em;

  @media (min-width: ${theme.breakpoints.tabletPortrait}){
    font-size: 3.2em;
  }
}

h4, .h4 {
  font-size: 1.8em;
}

h5, .h5  {
  font-size: 1.5em;
}

p, .p {
  font-size: 1.8em;

  &.p--small {
    font-size: 1.4em;
  }

  a, .a
  button {
    font-size:1em;
  }
}

a, .a {
  font-size: 1.6em;
}

small, .small {
  font-size: 1.2em;
}



label,
select,
textarea,
input[type="text"],
input[type="email"] {

}

button,
input[type="submit"] {
  font-size: 1.7em;
}

.c-button {
  display: block;
  -webkit-appearance: none;
  font-family: FatFrank-Regular;
  font-size: 2.4em;
  width: 294px;
  height: 60px;
  border: none;
  border-radius: 20px;

  &--white {
    background-color: white;
  }

  &--brown {
    background-color: ${theme.color.darkbrown};
    color: ${theme.color.white};
  }
}

`;
