import styled from 'styled-components';
import theme from '../theme';

export const HomeContainer = styled.div`
  position: relative;
  font-family: BrandonGrotesque-Bold;
  width: 100%;
  z-index: 0;
  height: 100%;
  display: grid;
  align-items: center;
  .c-home__content {
    width: 100%;
    display: grid;
    align-items: center;

    h1 {
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      font-family: 'Avenir-Roman';
      font-size: 2.1em;
      text-align: center;

      /*  padding-left: 8.47458%;
 padding-right: 8.47458%;*/

      @media (max-width: 768px) {
        /*flex-direction: column;*/
      }

      /*@include breakpoint($mobile-mid-landscape) {
        @include pad(0);
      }*/
    }

    @media (min-width: ${theme.breakpoints.tabletPortrait}) {
      h1,
      p {
        padding-left: 8.47458%;
        padding-right: 8.47458%;
      }
    }

    img {
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -4.5px;
    }
  }
`;
