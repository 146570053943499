import styled from 'styled-components';
import theme from '../theme';

export const FooterContainer = styled.div`
  .l-footer {
    position: relative;
    width: 100%;

    .o-container {
      text-align: center;

      p {
        width: 100%;
        padding-left: 8.47458%;
        padding-right: 8.47458%;
        position: relative;
        margin: auto;
        margin-bottom: 10px;
        color: ${theme.color.grey};
        font-size: 14px;
      }

      img {
        position: absolute;
        bottom: -10px;

        display: none;
        @media (min-width: ${theme.breakpoints.tabletPortrait}) {
          display: block;
        }
      }

      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        width: 400px;
      }
    }

    &__footer {
      width: 50px;
      position: absolute;
      z-index: 1;
      bottom: -70;
      left: 10%;
      opacity: 0.2;
      display: none;
      @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        display: block;
        width: 137px;
      }
    }
  }
`;
